import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICoWorking, IPaginatedResponse, IPaginationState } from '../../network/types';
import { storeEntities, storePage } from './utils';

const initialState: {
	items: Record<string, ICoWorking>;
	pagination?: IPaginationState<number>;
} = {
	items: {},
	pagination: undefined,
};

function fixRoomsSort(coWorking: ICoWorking) {
	if (coWorking.rooms) {
		coWorking.rooms = coWorking.rooms.sort((a, b) => a.title.localeCompare(b.title));
	}
}

const coWorkingsSlice = createSlice({
	name: 'coworkings',
	initialState,
	reducers: {
		handleCoWorkingsPage: (
			state,
			{ payload }: PayloadAction<IPaginatedResponse<ICoWorking>>
		) => {
			state.items = storeEntities(payload.data, state.items);
			payload.data.forEach(fixRoomsSort);
			state.pagination = storePage(
				state.pagination,
				payload.metadata,
				i => payload.data[i].id
			);
		},
		handleCoWorking: (state, { payload: { data } }: PayloadAction<{ data: ICoWorking }>) => {
			fixRoomsSort(data);
			state.items[data.id] = data;
		},
	},
});

export const { handleCoWorkingsPage, handleCoWorking } = coWorkingsSlice.actions;

export const transformCoWorkingsIn = (state: typeof initialState) => {
	const coWorkings: Record<string, ICoWorking> = {};
	for (const id in state.items) {
		coWorkings[id] = {
			...state.items[id],
			openspace: state.items[id].openspace
				? {
						...state.items[id].openspace,
						photos: undefined,
				  }
				: undefined,
			rooms: [
				...state.items[id].rooms.map(room => ({
					...room,
					photos: undefined,
				})),
			],
			photos: undefined,
		};
	}
	return { ...state, items: coWorkings };
};

export default coWorkingsSlice.reducer;
