import { Action } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';

import { Endpoint, EndpointResult } from '../config/endpoints';
import { IPaginatedState, IPaginationParams } from '../network/types';
import { RootState } from '../redux/store';
import { TypedRequestOptions, useDataLoader } from './useDataLoader';

export function usePaginatedDataLoader<
	D extends { id: string | number },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	E extends Endpoint<string, IPaginationParams, any>
>(
	key: string,
	dataSelector: (state: RootState) => IPaginatedState<D>,
	optionsOrCreator: TypedRequestOptions<E> | { (): TypedRequestOptions<E> },
	handlerAction: (response: EndpointResult<E>) => Action,
	usePreloaded = true
) {
	const { loading, data, fetch } = useDataLoader(
		key,
		dataSelector,
		optionsOrCreator,
		handlerAction,
		usePreloaded
	);
	const { canFetchMore, fetchMore } = useMemo(() => {
		if (!data) return {};

		const { skip, count, totalCount } = data.pagination;
		const skipNext = skip + count;
		const canFetchMore = skipNext < totalCount;

		const fetchMore = async (signal?: AbortSignal) => {
			if (!canFetchMore) return;
			await fetch({ skip: skipNext } as never, signal);
		};

		return { canFetchMore, fetchMore };
	}, [data, fetch]);
	const onFetchMore = useCallback((signal: AbortSignal) => fetchMore(signal), [fetchMore]);

	return { loading, data, fetch, canFetchMore, fetchMore, onFetchMore };
}
