import classNames from 'classnames';
import {
	forwardRef,
	ReactNode,
	RefObject,
	UIEventHandler,
	useImperativeHandle,
	useRef,
} from 'react';

import styles from './ScrollView.module.scss';

import { EndReachedEvent, EndReachedHandler, useEndReached } from '../../hooks/useEndReached';
import { useStableCallback } from '../../hooks/useStableCallback';

type ScrollViewRef = {
	viewport: RefObject<HTMLDivElement | null>;
	detectElementEnd: () => EndReachedEvent<HTMLDivElement> | undefined;
};

type ScrollViewProps = Omit<JSX.IntrinsicElements['div'], 'children' | 'ref'> & {
	onEndReached?: EndReachedHandler<HTMLDivElement>;
	endThreshold?: number;
	endPosition?: 'bottom' | 'top';
	children: ReactNode;
};

export const ScrollView = forwardRef<ScrollViewRef, ScrollViewProps>(function ScrollView(
	{ onEndReached, endThreshold, endPosition, onScroll, className, children, ...divProps },
	ref
) {
	const divRef = useRef<HTMLDivElement>(null);
	const { onScroll: detectEndOnScroll, detectElementEnd } = useEndReached(
		divRef,
		onEndReached,
		endThreshold,
		endPosition
	);
	const handleScroll = useStableCallback<UIEventHandler<HTMLDivElement>>(event => {
		onScroll?.(event);
		if (event.isDefaultPrevented()) return;
		detectEndOnScroll(event);
	});

	useImperativeHandle(
		ref,
		() => ({
			viewport: divRef,
			detectElementEnd: () => detectElementEnd(divRef.current),
		}),
		[detectElementEnd]
	);

	return (
		<div
			{...divProps}
			ref={divRef}
			className={classNames(styles.container, className)}
			onScroll={handleScroll}
		>
			{children}
		</div>
	);
});
