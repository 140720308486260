import { ComponentProps, ComponentRef, ReactElement, useMemo, useRef } from 'react';

import styles from './DataScrollView.module.scss';

import { useCancelableActionCallback } from '../../hooks/useActionCallback';
import { EndReachedHandler } from '../../hooks/useEndReached';
import { useStableCallback } from '../../hooks/useStableCallback';
import { ScrollView } from './ScrollView';

type DataScrollViewProps = ComponentProps<typeof ScrollView> & {
	canFetchMore?: boolean;
	fetchIndicator?: ReactElement | null;
	onFetchMore?: (signal: AbortSignal) => Promise<void>;
};

export function DataScrollView({
	canFetchMore,
	onFetchMore,
	fetchIndicator,
	endPosition = 'bottom',
	onEndReached,
	onScroll,
	children,
	...scrollProps
}: DataScrollViewProps) {
	const scrollViewRef = useRef<ComponentRef<typeof ScrollView>>(null);

	const continuousFetch = useMemo(() => {
		let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
		const cancel = () => {
			clearTimeout(timeout);
			timeout = undefined;
		};
		return Object.assign(
			() => {
				timeout = setTimeout(() => scrollViewRef.current.detectElementEnd(), 500);
			},
			{ cancel }
		);
	}, []);

	const [fetchMore, fetchMoreState] = useCancelableActionCallback(signal => async () => {
		continuousFetch.cancel();
		await onFetchMore?.(signal);
		continuousFetch();
	});
	const handleEndReached = useStableCallback<EndReachedHandler<HTMLDivElement>>(event => {
		continuousFetch.cancel();
		onEndReached?.(event);
		if (event.isDefaultPrevented()) return;
		if (canFetchMore && fetchMoreState.status !== 'pending') fetchMore();
	});

	const showIndicator = canFetchMore || fetchMoreState.status === 'pending';

	return (
		<ScrollView
			{...scrollProps}
			ref={scrollViewRef}
			onScroll={event => {
				continuousFetch.cancel();
				onScroll?.(event);
			}}
			onEndReached={handleEndReached}
		>
			{showIndicator && endPosition === 'top' ? (
				<>
					{fetchIndicator}
					<div className={styles.spacer} />
				</>
			) : null}
			{children}
			{showIndicator && endPosition === 'bottom' ? (
				<>
					<div className={styles.spacer} />
					{fetchIndicator}
				</>
			) : null}
		</ScrollView>
	);
}
